<template>
  <div>
    <div class="report-box">
      <div class="report">
        <div class="report-title">
          <div class="title-l">
            <span class="short">心铭远单词速记</span>
            >
            <span class="inform">总体报告</span>
          </div>
          <div class="title-r">
            <span class="back" @click="gotoXuexi">返回课程中心</span>
          </div>
        </div>
        <div class="report-container">
          <div class="report-info">
            <div class="info-choose">
              <span
                :class="['info-r', tab_index == 0 ? 'reCheck' : '']"
                @click="clkTab(0)"
                >学习报告</span
              >
              <span
                :class="['info-c', tab_index == 1 ? 'reCheck' : '']"
                @click="clkTab(1)"
                >课程进度报告</span
              >
            </div>
            <div class="info-content" v-if="tab_index == 1">
              <div class="ic-title">
                <div class="aiWord-box i-box chooseLine">
                  <span class="aiWord">智能单词</span>
                  <div
                    class="aiWord-line box-line"
                    style="display: block"
                  ></div>
                </div>
                <!--<div class="hear-box i-box">
                  <span class="hear">听说读写</span>
                  <div class="hear-line box-line"></div>
                </div>
                <div class="train-box i-box">
                  <span class="train">翻译训练</span>
                  <div class="train-line box-line"></div>
                </div>
                <div class="spell-box i-box">
                  <span class="spell">自然拼读</span>
                  <div class="spell-line box-line"></div>
                </div>-->
              </div>
              <!--<div class="ic-btn">
                                <span class="count icChoose">课程统计</span>
                                <span class="week">每周报告</span>
                                <span class="month">每月报告</span>
                            </div>-->
              <div
                class="ic-progress"
                v-for="(item, index) in tempoData"
                :key="index"
              >
                <div class="p-title">
                  <div class="p-l">
                    <span>{{ item.name }}</span>
                  </div>
                  <!--<div class="p-r">
                    <span class="p-r-word">单词数：</span
                    ><span class="word-num">194</span>
                  </div>-->
                </div>

                <!--<div class="p-title">
                                    <div class="p-l">
                                         <span
                                            >外研社JoinIn2013（三年级起点）</span
                                        >
                                        —
                                        <span>三上</span>
                                        <el-select v-model="value" placeholder="请选择">
                                            <el-option
                                                    v-for="item in options"
                                                    :key="item.value"
                                                    :label="item.label"
                                                    :value="item.value">
                                            </el-option>
                                        </el-select>
                                    </div>
                                    <div class="p-r">
                                        <span class="p-r-word">单词数：</span
                                        ><span class="word-num">194</span>
                                        <span class="p-r-word">效率：</span
                                        ><span class="ratio-num">78%</span>
                                    </div>
                                </div>-->
                <div class="p-info">
                  <ul class="info-list">
                    <li
                      class="in-item"
                      v-for="(c_item, c_index) in item.jindu"
                      :key="c_index"
                    >
                      <div class="in-content">
                        <span class="inTitle">{{ c_item.name }}</span>
                        <span class="time-text">
                            (第
                          <span class="times">{{ c_item.xuexi_num }}</span>
                            遍)
                        </span>
                        <el-progress
                          :percentage="c_item.percentage"
                          :height="150"
                          :width="prWidth"
                          :text-inside="!inside"
                          color="#2ac2a1"
                        ></el-progress>
                      </div>
                      <div class="unit-count" style="">
                        <el-tooltip
                          class="item"
                          v-for="(dy_item, dy_index) in c_item.danyuan"
                          effect="dark"
                          :key="dy_index"
                          :content="dy_item.name"
                          placement="bottom"
                        >
                          <i
                            class="fa fa-circle-o test-error"
                            :key="dy_index"
                            v-if="
                              dy_item.is_xuewan == 0 &&
                              dy_item.right_word_num == 0
                            "
                          ></i>
                          <i
                            class="fa fa-adjust"
                            :key="dy_index"
                            v-if="
                              dy_item.is_xuewan == 0 &&
                              dy_item.right_word_num > 0
                            "
                          ></i>
                          <i
                            class="fa fa-circle test-error"
                            :key="dy_index"
                            v-if="
                              dy_item.is_xuewan == 1 && dy_item.ceshi_num == 0
                            "
                          ></i>
                          <i
                            class="fa fa-check-circle"
                            :key="dy_index"
                            v-if="
                              dy_item.is_xuewan == 1 &&
                              dy_item.fen >= 90 &&
                              dy_item.ceshi_num > 0 &&
                              dy_item.ks_level == 1
                            "
                          ></i>
                          <i
                            class="fa fa-times-circle"
                            style="font-size: 0.15rem"
                            :key="dy_index"
                            v-if="
                              dy_item.is_xuewan == 1 &&
                              dy_item.fen < 90 &&
                              dy_item.ceshi_num > 0 &&
                              dy_item.ks_level == 1
                            "
                          ></i>
                          <i
                            class="iconfont icon-shandian"
                            :key="dy_index"
                            v-if="
                              dy_item.is_xuewan == 1 &&
                              dy_item.ceshi_num > 0 &&
                              dy_item.ks_level == 2
                            "
                          ></i>
                          <i
                            class="iconfont icon-huangguan"
                            :key="dy_index"
                            v-if="
                              dy_item.is_xuewan == 1 &&
                              dy_item.ceshi_num > 0 &&
                              dy_item.ks_level == 3
                            "
                          ></i>
                        </el-tooltip>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="info-content" v-if="tab_index == 0">
              <div class="ic-title">
                <div :class="[tab_child_index==0?'chooseLine':'','aiWord-box i-box']" @click="clkXuexiTabChild(0)">
                  <span class="aiWord">智能单词</span>
                  <div
                    :class="[tab_child_index==0?'box-line display_block':'','aiWord-line']"
                  ></div>
                </div>
                <div :class="[tab_child_index==1?'chooseLine':'','hear-box i-box']" @click="clkXuexiTabChild(1)">
                  <span class="hear">例句学习</span>
                  <div :class="[tab_child_index==1?'box-line display_block':'','hear-line']"></div>
                </div>                
              </div>
              <div class="content-box">
                <div class="box-tips">
                  <span
                    >学习报告展现了你一周内学习的单词数量、学习时间、本月学习时间以及测试成绩等数据。</span
                  >
                </div>
                <div class="box-table" v-if="tab_child_index==0">
                  <el-table
                    border
                    max-height="800px"
                    :header-cell-style="{
                      textAlign: 'center',
                      background: '#fdf6ea',
                      paddingTop: '0',
                    }"
                    :cell-style="{
                      fontSize: '12px',
                      textAlign: 'center',
                      fontWeight: '400',
                      cursor: 'pointer',
                    }"
                    :data="tableData"
                    style="width: 100%"
                  >
                    <el-table-column prop="num" label="序号" width="50">
                    </el-table-column>
                    <el-table-column prop="name" label="书本" width="230">
                    </el-table-column>
                    <el-table-column
                      prop="type_name"
                      label="测试类型"
                      width="170"
                    >
                    </el-table-column>
                    <el-table-column prop="danyuan_ids_name" label="单元">
                    </el-table-column>

                    <el-table-column
                      prop="created_at"
                      label="测试时间"
                      width="160"
                    ></el-table-column>
                    <el-table-column
                      prop="yongshi_time"
                      label="测试耗时"
                      width="100"
                    ></el-table-column>
                    <el-table-column
                      prop="inter"
                      label="分数"
                      width="50"
                    ></el-table-column>
                    <el-table-column prop="pass" label="通过" width="50">
                      <template slot-scope="scope">
                        <i
                          class="fa fa-check-circle"
                          v-if="scope.row.is_tongguo ==1"
                        ></i>
                        <i
                          class="fa fa-times-circle"
                          v-if="scope.row.is_tongguo ==0"
                        ></i>
                      </template>
                    </el-table-column>
                  </el-table>
                  <div class="footer-page">
                    <div class="p-info">
                      第<span class="p-num">{{ page }}</span
                      >/ <span class="p-total">{{ allPage }}</span
                      >页，共 <span class="p-data">{{ size }}</span
                      >条数据
                    </div>
                    <el-pagination
                      background
                      layout="prev, pager, next"
                      @current-change="handleCurrentChange"
                      :total="size"
                      :hide-on-single-page="false"
                      :page-size="pageSize"
                      :current-page="page"
                    >
                    </el-pagination>
                  </div>
                </div>
                <div class="box-table" v-if="tab_child_index==1">
                  <el-table
                    border
                    max-height="800px"
                    :header-cell-style="{
                      textAlign: 'center',
                      background: '#fdf6ea',
                      paddingTop: '0',
                    }"
                    :cell-style="{
                      fontSize: '12px',
                      textAlign: 'center',
                      fontWeight: '400',
                      cursor: 'pointer',
                    }"
                    :data="tableDataLiju"
                    style="width: 100%"
                  >
                    <el-table-column prop="num" label="序号" width="50">
                    </el-table-column>
                    <el-table-column prop="course.name" label="书本" width="230">
                    </el-table-column>
                    <el-table-column
                      prop="type_name"
                      label="测试类型"
                      width="170"
                    >
                    </el-table-column>
                    <el-table-column prop="danyuan.name" label="单元">
                    </el-table-column>
                    <el-table-column prop="last_word_num" label="学习数量">
                    </el-table-column>
                    <el-table-column
                      prop="updated_at"
                      label="学习时间"
                      width="160"
                    ></el-table-column>
                    <el-table-column
                      prop="yongshi_time"
                      label="学习耗时"
                      width="100"
                    ></el-table-column>
                    <el-table-column prop="pass" label="是否学完本单元" width="150">
                      <template slot-scope="scope">
                        <i
                          class="fa fa-check-circle"
                          v-if="scope.row.is_xuewan ==1"
                        ></i>
                        <i
                          class="fa fa-times-circle"
                          v-if="scope.row.is_xuewan ==0"
                        ></i>
                      </template>
                    </el-table-column>

                  </el-table>
                  <div class="footer-page">
                    <div class="p-info">
                      第<span class="p-num">{{ page }}</span
                      >/ <span class="p-total">{{ allPage }}</span
                      >页，共 <span class="p-data">{{ size }}</span
                      >条数据
                    </div>
                    <el-pagination
                      background
                      layout="prev, pager, next"
                      @current-change="handleCurrentChange"
                      :total="size"
                      :hide-on-single-page="false"
                      :page-size="pageSize"
                      :current-page="page"
                    >
                    </el-pagination>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  data() {
    return {
      inside: true,
      percentage: 22,
      prWidth: 150,
      tab_index: 0,
      tab_child_index:0,
      infoList: [
        { title: "智能记忆", times: "1" },
        { title: "智能词听", times: "1" },
        { title: "智能听写", times: "1" },
        { title: "智能默写", times: "1" },
        // { title: "例句听组", times: "1" },
        // { title: "例句听力", times: "1" },
        // { title: "例句翻译", times: "1" },
        // { title: "例句默写", times: "1" },
      ],
      tableData: [],
      tempoData: [],
      tableDataLiju:[],
      page: 1,
      pageSize: 10,
      size: 0,
      options: [
        {
          value: "选项1",
          label: "外研社JoinIn2013（三年级起点）",
        },
        {
          value: "选项2",
          label: "【心铭远】外研版课标词汇七年级（上）",
        },
        {
          value: "选项3",
          label: "蚵仔煎",
        },
        {
          value: "选项4",
          label: "龙须面",
        },
        {
          value: "选项5",
          label: "北京烤鸭",
        },
      ],
      value: "",
    };
  },
  updated() {
    let popper = document.querySelector("body>.el-popper");
    console.log(popper, 11);
  },
  computed: {
    ...mapState({
      token: (state) => state.user.token,
      user: (state) => state.user.user,
    }),
    allPage() {
      return Math.ceil(this.size / this.pageSize);
    },
  },
  created() {
    this.getReport();
    this.tempoReport();
  },
  methods: {
    getReport() {
      //学习报告
      let than = this;
      this.loading = true;
      let params = {
        token: this.token,
        page: this.page,
        pageSize: this.pageSize,
      };
      this.tableData = [];
      this.axios
        .post("/api/test_center/study_report", params)
        .then((res) => {
          console.log(res);
          if (res.data.status_code == 200) {
            this.tableData = res.data.data.lists;
            this.size = res.data.data.count;
          } else if (res.data.status_code == 202) {
          } else {
            this.$message(res.data.msg);
          }
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    getReportLiju() {
      //例句学习报告
      let than = this;
      this.loading = true;
      let params = {
        token: this.token,
        page: this.page,
        pageSize: this.pageSize,
      };
      this.tableDataLiju = [];
      this.axios
        .post("/api/test_center/study_report_liju", params)
        .then((res) => {
          console.log(res);
          if (res.data.status_code == 200) {
            this.tableDataLiju = res.data.data.lists;
            this.size = res.data.data.count;
          } else if (res.data.status_code == 202) {
          } else {
            this.$message(res.data.msg);
          }
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    tempoReport() {
      //进度报告
      let than = this;
      this.loading = true;
      let params = {
        token: this.token,
        page: this.page,
        pageSize: this.pageSize,
      };
      this.tempoData = [];
      this.axios
        .post("/api/test_center/study_report_jindu", params)
        .then((res) => {
          console.log(res);
          if (res.data.status_code == 200) {
            this.tempoData = res.data.data.lists;
            console.log("tempoData", this.tempoData);
          } else if (res.data.status_code == 202) {
          } else {
            this.$message(res.data.msg);
          }
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    clkTab(index) {
      this.tab_index = index;
    },
    handleCurrentChange(page) {
      this.page = page;
      if(this.tab_child_index==0){
            this.getReport();
        }else if(this.tab_child_index==1){
            this.getReportLiju();
        }
    },
    gotoXuexi() {
      this.$router.push({ name: "index" });
    },
    clkXuexiTabChild(index){
        this.tab_child_index = index;
        this.page = 1;
        if(this.tab_child_index==0){
            this.getReport();
        }else if(this.tab_child_index==1){
            this.getReportLiju();
        }
    }
  },
};
</script>

<style lang="scss">
@import "~@/assets/css/study_report.scss";
.display_block{
  display: block !important;
}
</style>