var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"report-box"},[_c('div',{staticClass:"report"},[_c('div',{staticClass:"report-title"},[_vm._m(0),_c('div',{staticClass:"title-r"},[_c('span',{staticClass:"back",on:{"click":_vm.gotoXuexi}},[_vm._v("返回课程中心")])])]),_c('div',{staticClass:"report-container"},[_c('div',{staticClass:"report-info"},[_c('div',{staticClass:"info-choose"},[_c('span',{class:['info-r', _vm.tab_index == 0 ? 'reCheck' : ''],on:{"click":function($event){return _vm.clkTab(0)}}},[_vm._v("学习报告")]),_c('span',{class:['info-c', _vm.tab_index == 1 ? 'reCheck' : ''],on:{"click":function($event){return _vm.clkTab(1)}}},[_vm._v("课程进度报告")])]),(_vm.tab_index == 1)?_c('div',{staticClass:"info-content"},[_vm._m(1),_vm._l((_vm.tempoData),function(item,index){return _c('div',{key:index,staticClass:"ic-progress"},[_c('div',{staticClass:"p-title"},[_c('div',{staticClass:"p-l"},[_c('span',[_vm._v(_vm._s(item.name))])])]),_c('div',{staticClass:"p-info"},[_c('ul',{staticClass:"info-list"},_vm._l((item.jindu),function(c_item,c_index){return _c('li',{key:c_index,staticClass:"in-item"},[_c('div',{staticClass:"in-content"},[_c('span',{staticClass:"inTitle"},[_vm._v(_vm._s(c_item.name))]),_c('span',{staticClass:"time-text"},[_vm._v(" (第 "),_c('span',{staticClass:"times"},[_vm._v(_vm._s(c_item.xuexi_num))]),_vm._v(" 遍) ")]),_c('el-progress',{attrs:{"percentage":c_item.percentage,"height":150,"width":_vm.prWidth,"text-inside":!_vm.inside,"color":"#2ac2a1"}})],1),_c('div',{staticClass:"unit-count"},_vm._l((c_item.danyuan),function(dy_item,dy_index){return _c('el-tooltip',{key:dy_index,staticClass:"item",attrs:{"effect":"dark","content":dy_item.name,"placement":"bottom"}},[(
                            dy_item.is_xuewan == 0 &&
                            dy_item.right_word_num == 0
                          )?_c('i',{key:dy_index,staticClass:"fa fa-circle-o test-error"}):_vm._e(),(
                            dy_item.is_xuewan == 0 &&
                            dy_item.right_word_num > 0
                          )?_c('i',{key:dy_index,staticClass:"fa fa-adjust"}):_vm._e(),(
                            dy_item.is_xuewan == 1 && dy_item.ceshi_num == 0
                          )?_c('i',{key:dy_index,staticClass:"fa fa-circle test-error"}):_vm._e(),(
                            dy_item.is_xuewan == 1 &&
                            dy_item.fen >= 90 &&
                            dy_item.ceshi_num > 0 &&
                            dy_item.ks_level == 1
                          )?_c('i',{key:dy_index,staticClass:"fa fa-check-circle"}):_vm._e(),(
                            dy_item.is_xuewan == 1 &&
                            dy_item.fen < 90 &&
                            dy_item.ceshi_num > 0 &&
                            dy_item.ks_level == 1
                          )?_c('i',{key:dy_index,staticClass:"fa fa-times-circle",staticStyle:{"font-size":"0.15rem"}}):_vm._e(),(
                            dy_item.is_xuewan == 1 &&
                            dy_item.ceshi_num > 0 &&
                            dy_item.ks_level == 2
                          )?_c('i',{key:dy_index,staticClass:"iconfont icon-shandian"}):_vm._e(),(
                            dy_item.is_xuewan == 1 &&
                            dy_item.ceshi_num > 0 &&
                            dy_item.ks_level == 3
                          )?_c('i',{key:dy_index,staticClass:"iconfont icon-huangguan"}):_vm._e()])}),1)])}),0)])])})],2):_vm._e(),(_vm.tab_index == 0)?_c('div',{staticClass:"info-content"},[_c('div',{staticClass:"ic-title"},[_c('div',{class:[_vm.tab_child_index==0?'chooseLine':'','aiWord-box i-box'],on:{"click":function($event){return _vm.clkXuexiTabChild(0)}}},[_c('span',{staticClass:"aiWord"},[_vm._v("智能单词")]),_c('div',{class:[_vm.tab_child_index==0?'box-line display_block':'','aiWord-line']})]),_c('div',{class:[_vm.tab_child_index==1?'chooseLine':'','hear-box i-box'],on:{"click":function($event){return _vm.clkXuexiTabChild(1)}}},[_c('span',{staticClass:"hear"},[_vm._v("例句学习")]),_c('div',{class:[_vm.tab_child_index==1?'box-line display_block':'','hear-line']})])]),_c('div',{staticClass:"content-box"},[_vm._m(2),(_vm.tab_child_index==0)?_c('div',{staticClass:"box-table"},[_c('el-table',{staticStyle:{"width":"100%"},attrs:{"border":"","max-height":"800px","header-cell-style":{
                    textAlign: 'center',
                    background: '#fdf6ea',
                    paddingTop: '0',
                  },"cell-style":{
                    fontSize: '12px',
                    textAlign: 'center',
                    fontWeight: '400',
                    cursor: 'pointer',
                  },"data":_vm.tableData}},[_c('el-table-column',{attrs:{"prop":"num","label":"序号","width":"50"}}),_c('el-table-column',{attrs:{"prop":"name","label":"书本","width":"230"}}),_c('el-table-column',{attrs:{"prop":"type_name","label":"测试类型","width":"170"}}),_c('el-table-column',{attrs:{"prop":"danyuan_ids_name","label":"单元"}}),_c('el-table-column',{attrs:{"prop":"created_at","label":"测试时间","width":"160"}}),_c('el-table-column',{attrs:{"prop":"yongshi_time","label":"测试耗时","width":"100"}}),_c('el-table-column',{attrs:{"prop":"inter","label":"分数","width":"50"}}),_c('el-table-column',{attrs:{"prop":"pass","label":"通过","width":"50"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(scope.row.is_tongguo ==1)?_c('i',{staticClass:"fa fa-check-circle"}):_vm._e(),(scope.row.is_tongguo ==0)?_c('i',{staticClass:"fa fa-times-circle"}):_vm._e()]}}],null,false,1910299413)})],1),_c('div',{staticClass:"footer-page"},[_c('div',{staticClass:"p-info"},[_vm._v(" 第"),_c('span',{staticClass:"p-num"},[_vm._v(_vm._s(_vm.page))]),_vm._v("/ "),_c('span',{staticClass:"p-total"},[_vm._v(_vm._s(_vm.allPage))]),_vm._v("页，共 "),_c('span',{staticClass:"p-data"},[_vm._v(_vm._s(_vm.size))]),_vm._v("条数据 ")]),_c('el-pagination',{attrs:{"background":"","layout":"prev, pager, next","total":_vm.size,"hide-on-single-page":false,"page-size":_vm.pageSize,"current-page":_vm.page},on:{"current-change":_vm.handleCurrentChange}})],1)],1):_vm._e(),(_vm.tab_child_index==1)?_c('div',{staticClass:"box-table"},[_c('el-table',{staticStyle:{"width":"100%"},attrs:{"border":"","max-height":"800px","header-cell-style":{
                    textAlign: 'center',
                    background: '#fdf6ea',
                    paddingTop: '0',
                  },"cell-style":{
                    fontSize: '12px',
                    textAlign: 'center',
                    fontWeight: '400',
                    cursor: 'pointer',
                  },"data":_vm.tableDataLiju}},[_c('el-table-column',{attrs:{"prop":"num","label":"序号","width":"50"}}),_c('el-table-column',{attrs:{"prop":"course.name","label":"书本","width":"230"}}),_c('el-table-column',{attrs:{"prop":"type_name","label":"测试类型","width":"170"}}),_c('el-table-column',{attrs:{"prop":"danyuan.name","label":"单元"}}),_c('el-table-column',{attrs:{"prop":"last_word_num","label":"学习数量"}}),_c('el-table-column',{attrs:{"prop":"updated_at","label":"学习时间","width":"160"}}),_c('el-table-column',{attrs:{"prop":"yongshi_time","label":"学习耗时","width":"100"}}),_c('el-table-column',{attrs:{"prop":"pass","label":"是否学完本单元","width":"150"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(scope.row.is_xuewan ==1)?_c('i',{staticClass:"fa fa-check-circle"}):_vm._e(),(scope.row.is_xuewan ==0)?_c('i',{staticClass:"fa fa-times-circle"}):_vm._e()]}}],null,false,1587926101)})],1),_c('div',{staticClass:"footer-page"},[_c('div',{staticClass:"p-info"},[_vm._v(" 第"),_c('span',{staticClass:"p-num"},[_vm._v(_vm._s(_vm.page))]),_vm._v("/ "),_c('span',{staticClass:"p-total"},[_vm._v(_vm._s(_vm.allPage))]),_vm._v("页，共 "),_c('span',{staticClass:"p-data"},[_vm._v(_vm._s(_vm.size))]),_vm._v("条数据 ")]),_c('el-pagination',{attrs:{"background":"","layout":"prev, pager, next","total":_vm.size,"hide-on-single-page":false,"page-size":_vm.pageSize,"current-page":_vm.page},on:{"current-change":_vm.handleCurrentChange}})],1)],1):_vm._e()])]):_vm._e()])])])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"title-l"},[_c('span',{staticClass:"short"},[_vm._v("心铭远单词速记")]),_vm._v(" > "),_c('span',{staticClass:"inform"},[_vm._v("总体报告")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"ic-title"},[_c('div',{staticClass:"aiWord-box i-box chooseLine"},[_c('span',{staticClass:"aiWord"},[_vm._v("智能单词")]),_c('div',{staticClass:"aiWord-line box-line",staticStyle:{"display":"block"}})])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"box-tips"},[_c('span',[_vm._v("学习报告展现了你一周内学习的单词数量、学习时间、本月学习时间以及测试成绩等数据。")])])}]

export { render, staticRenderFns }